<template>
  <div class="inputs-form">
    <div class="row inputs-form__date">
      <label class="row__input-label">Birth date *<br/></label>
      <Calendar id="icon" v-model="birthDate" :showIcon="true"
      :manualInput="false" dateFormat="yy/mm/dd" placeholder="Year/month/day"/>
      <span class="row__error" v-if="msg.birthDate">{{msg.birthDate}}</span>
    </div>
    <div class="row inputs-form__row">
      <div>
        <label class="row__input-label"> ID<br /> </label>
        <input class="row__input" id="id" v-model="id"
        autocomplete="off" placeholder="Enter code"/>
        <span class="row__error" v-if="msg.id">{{msg.id}}</span>
      </div>
      <div>
        <label class="row__input-label"> Passport number<br /> </label>
        <input class="row__input" id="passport" v-model="passport"
        autocomplete="off" placeholder="Enter code"/>
        <span class="row__error" v-if="msg.passport">{{msg.passport}}</span>
      </div>
    </div>
    <div class="row inputs-form__row">
      <div>
        <label class="row__input-label"> Contact phone *<br /> </label>
        <InputNumber v-model="contactPhone" mode="decimal" :min="0" :useGrouping="false"
        autocomplete="off" placeholder="Enter number" @input="validatePhone"/>
        <span class="row__error" v-if="msg.contactPhone">{{msg.contactPhone}}</span>
      </div>
      <div>
        <label class="row__input-label">Second contact phone<br /> </label>
        <InputNumber v-model="secondContactPhone" mode="decimal" :min="0"
        autocomplete="off" placeholder="Enter number" :useGrouping="false"/>
        <span class="row__error" v-if="msg.secondContactPhone">{{msg.secondContactPhone}}</span>
      </div>
    </div><div class="row inputs-form__row">
      <div>
        <label class="row__input-label">Alternative contact name<br /> </label>
        <input class="row__input" id="alternativeName" v-model="alternativeName"
        autocomplete="off" placeholder="Enter name"/>
        <span class="row__error" v-if="msg.alternativeName">{{msg.alternativeName}}</span>
      </div>
      <div>
        <label class="row__input-label">Alternative contact number<br /> </label>
        <InputNumber v-model="alternativeNumber" mode="decimal" :min="0"
        autocomplete="off" placeholder="Enter number" :useGrouping="false"/>
        <span class="row__error" v-if="msg.alternativeNumber">{{msg.alternativeNumber}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputsForm',

  data() {
    return {
      birthDate: '',
      id: '',
      passport: '',
      contactPhone: null,
      secondContactPhone: null,
      alternativeName: '',
      alternativeNumber: null,
      msg: [],
      formCorrect: false,
    };
  },
  watch: {
    birthDate(value) {
      this.validateAge(value);
    },
    id(value) {
      // binding this to the data value in the email input
      this.validateCode(value, 'id');
    },
    passport(value) {
      // binding this to the data value in the email input
      this.validateCode(value, 'passport');
    },
    alternativeName(value) {
      this.validateName(value);
    },
  },
  methods: {
    submit() {
      return {
        birth_date: JSON.stringify(this.birthDate).replace('"', '').split('T')[0],
        dni: this.id,
        passport_numbre: this.passport,
        contact_phone: this.contactPhone,
        second_contact_phone: this.secondContactPhone,
        alternative_contact_name: this.alternativeName,
        alternative_contact_number: this.alternativeNumber,
        bucket: true,
        attachend: '',
        profession_id: 0,
      };
    },

    validateAge(value) {
      const today = new Date();
      let age = today.getFullYear() - value.getFullYear();
      const m = today.getMonth() - value.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < value.getDate())) {
        age -= 1;
      }
      if (age < 18) {
        this.msg.birthDate = 'Sorry, you must be at least 18 years old';
      } else {
        this.msg.birthDate = '';
      }
    },
    validateCode(value, inputId) {
      if (/^[a-zA-Z0-9]*$/.test(value)) {
        this.msg[inputId] = '';
      } else {
        this.msg[inputId] = 'Invalid code';
      }
      this.checkForm();
    },
    validatePhone(value) {
      if (value !== null) {
        this.msg.contactPhone = '';
      } else {
        this.msg.contactPhone = 'This field is required';
      }
      this.checkForm();
    },
    validateName(value) {
      if (/^[a-zA-Z ]*$/.test(value)) {
        this.msg.alternativeName = '';
      } else {
        this.msg.alternativeName = 'Invalid name';
      }
      this.checkForm();
    },
    checkForm() {
      if (!(Object.values(this.msg).some((error) => error.length))
      && this.birthDate !== null && this.contactPhone !== null) {
        this.formCorrect = true;
      } else {
        this.formCorrect = false;
      }
      this.$emit('enableButton', this.formCorrect);
    },
  },
};
</script>

<style lang="scss" scoped>
.inputs-form {
  margin: .5rem 2rem;
  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  }
  &__date {
    margin-bottom: 1rem;
  }
}
.row {
  margin-bottom: 1rem;
  &__input-label {
    margin: 0 0 0 0.5rem;
  }
  &__input {
    @include input(100%);
    min-width: 5rem;
  }
  &__error {
    color: $alert-color;
    font-size: 12px;
    margin: 0 0 0 0.5rem;
  }
}
.p-calendar {
  width: 48%;
}
::v-deep .p-inputtext {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  &:hover {
    border-color: $secondary-color;
  }
}
::v-deep .p-datepicker-trigger {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background: $primary-color;
  border-color: $primary-color;
  &:hover {
    background: $primary-color-lighter;
    border-color: $primary-color-lighter;
  }
}
::v-deep .p-inputnumber-input {
  @include input(100%);
  font-size: 12.5px;
}
.p-inputnumber {
  width: 100%;
}
</style>

<template>
  <div class="footer">
    <button class="footer__secondary-button"
    @click="$emit('clickOnBack')">Back</button>
    <button class="footer__primary-button" :disabled="!isEnable"
    @click="$emit('clickOnContinue')">Continue</button>
  </div>
</template>

<script>
export default {
  name: 'FooterBotons',
  props: ['urlBack', 'urlContinue', 'isEnable'],
};
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 2rem;
  width: 100%;
  &__primary-button {
    @include primary-button();
    width: 18%;
    float: right;
  }
  &__secondary-button {
    @include secondary-button();
    width: 18%;
  }
}
</style>

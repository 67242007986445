<template>
  <div class="loading">
    <img src="../assets/images/Sppiner.gif" :width="size" :height="size" />
    <h3>Loading...</h3>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    size: String,
  },
};
</script>

<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    margin-top: 10px;
  }
  h5 {
    color: $secondary-color;
  }
}
</style>

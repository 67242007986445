<template>
  <div class="contact-data">
    <Loading v-if="isLoading" />
    <div class="content contact-data__content">
      <h1 class="content__tittle">Contact data</h1>
      <div v-if="!isLoading" class="content__form">
        <inputsForm ref="contactDataForm" @enableButton="enableButton"/>
        <footerBotons @clickOnBack='onBackClick()' @clickOnContinue='onContinueClick()'
        :isEnable="isEnable"/>
      </div>
    </div>
  </div>
</template>

<script>
import FooterBotons from './Components/FooterBotons.vue';
import InputsForm from './Components/InputsForm.vue';
import Loading from "../../components/Loading.vue";

export default {
  components: { FooterBotons, InputsForm, Loading },
  name: 'ContactData',
  data() {
    return {
      isEnable: false,
      isLoading: false,
    };
  },
  mounted() {
    // If information is not in local storage yet, we create it.
    if (!localStorage.getItem('freelance-registry-information')) {
      localStorage.setItem('freelance-registry-information', JSON.stringify({
        freelancer: {},
        education: {},
        freelancer_experticies: [],
      }));
    }
  },
  methods: {
    updateLocalStorageInfo(submitInfo) {
      // Retrieve freelancer information from local storage and update "freelancer" field
      const newFreelancerData = JSON.parse(localStorage.getItem('freelance-registry-information'));
      newFreelancerData.freelancer = submitInfo;
      localStorage.setItem('freelance-registry-information', JSON.stringify(newFreelancerData));
    },

    onContinueClick() {
      this.isLoading = true;
      const submitInfo = this.$refs.contactDataForm.submit();
      this.updateLocalStorageInfo(submitInfo);
      this.$router.push({ path: '/applicant-education' });
      this.isLoading = false;
    },

    onBackClick() {
      this.$router.push({ path: '/complete-account' });
    },

    enableButton(e) {
      this.isEnable = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-data {
  width: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: 0 6rem 1rem;
  }
}

.content {
  &__tittle {
    font-size: 2rem;
    text-align: center;
    margin-right: auto;
  }
}
</style>
